import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Boxes from "../components/boxes"
import CtaContact from "../components/cta-contact"
import Seo from "../components/seo"

const DataFoundationStrategy = ({ data }) => {

  const hero = {
    'textintro': "Data management and governance are the cornerstone of digital transformation.",
    'title': 'Data Foundation Strategy',
    'squares': '',
    'squaresmove': false,
  }

  const box1 = {
    'title': '',
    'content': [
      {
        'title': 'Resilience',
        'content': 'Global supply chain management flows are influenced by variability and complexity. Data strategy will help you be more agile and flexible to reduce risk.'
      },
      {
        'title': 'Liquidity and cash forecasting',
        'content': 'Produce key metrics quickly to improve your payment terms, supplier leverage, minimize working capital and rapidly gain insights for better decision making.',
      },
      {
        'title': 'Compliance',
        'content': 'Having resilient and flexible data models will keep you at the forefront of compliance regulations and help you avoid discrepancies between regulations in global markets.',
      }
    ],
  }

  return (
    <Layout
      linksnavcolor='black'
    >
      <Seo
        title={data.site.siteMetadata.title}
        description="Data management and governance are the cornerstone of digital transformation"
      />
      <Hero
        bg='linear-gradient(180deg, #ffceba 96%, #000000)'
        color='black'
        textintro={hero.textintro}
        title={hero.title}
        squares={hero.squares}
        squaresmove={hero.squaresmove}
      />
      <Boxes
        colorsquares='secondary'
        bg='linear-gradient(180deg, #000000, #1e1e1e)'
        title={box1.title}
        columns={box1.content.length}
        content={box1.content}
      />
      <CtaContact />
    </Layout>
  )
}

export default DataFoundationStrategy

export const query = graphql`
  query DataFoundationStrategy {
    site {
      siteMetadata {
        title
      }
    }
  }
`
