import React from "react"
import Text from "./text"
import bg20 from "../images/bg-20.png"

const CtaContact = () => {

  const text = {
    'title': '',
    'content': {
      'text': 'New World? What is your driver for change?',
      'link': 'contact',
      'textlink': 'Contact us'
    }
  }

  return (
    <Text
      bg={`#000000 url(${bg20}) no-repeat center top`}
      bgsize='cover'
      title={text.title}
      content={text.content}
    />
  )

}

export default CtaContact