import React from "react"
import { Link } from "gatsby"
import { Box, Button, Container, Grid, Heading, Themed } from "theme-ui"

const Text = ( props ) => {

  const color = props.color ? props.color : `#cfcfcf`
  const bg = props.bg ? props.bg : `black`
  const bgsize = props.bgsize ? props.bgsize : `auto`
  const title = props.title
  const content = props.content

  return (
    <Box
      as={`section`}
      className="text"
      sx={{
        background: `${bg}`,
        backgroundSize: `${bgsize}`,
        py: [5, 5, 5, 5, 6],
        position: `relative`,
        overflow: `hidden`,
        maxWidth: `100vw`,
        "h2, h3, h4, li, p, a, span": {
          color: `${color}`,
        },
        h2: {
          color: `tertiary`,
          fontSize: [0, 1],
          mt: 0,
        },
        p: {
          fontSize: [1, 1, 2, 2, 2],
          lineHeight: `heading`
        },
        a: {
          borderBottom: 0,
          "&:hover": {
            borderBottom: 0,
          },
        },
        button: {
          borderColor: `${color}`,
          a: {
            textDecoration: `none`,
          }
        },
        ".content": {
          alignItems: [`auto`, `center`],
        },
        ".bg-button": {
          textAlign: [`left`, `right`],
        }
      }}
    >
      <Container as={`div`}>
        <Grid
          as={`div`}
          className={`content`}
          gap={[0]}
          columns={[`1fr`, `1fr`, `1fr`, `2fr 1fr`, `1fr 1fr`]}
        >
          <Box as={`div`} className='bg-text'>
            <Heading as={`h2`}>{title}</Heading>
            <Themed.p>{content.text1}</Themed.p>
            <Themed.p>{content.text2}</Themed.p>
          </Box>
          {content.link &&
            <Box as={`div`} className='bg-button'>
              <Button><Link to={`/${content.link}`}>{content.textlink}</Link></Button>
            </Box>
          }
        </Grid>
      </Container>
    </Box>
  )
}

export default Text